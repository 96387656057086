<template>
  <div class="loading-overlay" v-if="isLoading">
    <div class="loading-box">
      <div class="loading-spinner"></div>
      <div class="loading-text b2">{{ loadingText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: "Sizi biraz bekletiyoruz...",
    },
  },
};
</script>

<style>
.loading-overlay {
  @apply fixed top-0 left-0 w-full h-full bg-neutral-base bg-opacity-50 flex justify-center items-center z-50;
}

.loading-box {
  @apply bg-neutral-base rounded-lg p-8 flex flex-col items-center select-none;
}

.loading-spinner {
  @apply border-4 border-solid border-[#FFFFFF55] border-t-[#FFFFFFFF] rounded-full w-12 h-12 animate-spin;
}

.loading-text {
  @apply mt-4 text-surface-base;
}
</style>
